import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    texts :{},
    language : 'en',
    sections : [],
    forms : [],
    clients : [],
    last360 : [],
    partners : [],
    services : [],
  },
  getters: {
    getTexts: state => state.texts,
    getLanguage: state => state.language,
    getSections: state => state.sections,
    getForms: state => state.forms,
    getClients: state => state.clients,
    getLast360: state => state.last360,
    getPartners: state => state.partners,
    getServices: state => state.services,
  },
  mutations: {
    setTexts(state, texts) {
      state.texts = texts
    },
    setLanguage(state, language) {
      state.language = language
    },
    setSections(state, sections) {
      state.sections = sections
    },
    setForms(state, forms) {
      state.forms = forms
    },
    setClients(state, clients) {
      state.clients = clients
    },
    setLast360(state, last360) {
      state.last360 = last360
    },
    setPartners(state, partners) {
      state.partners = partners
    },
    setServices(state, services) {
      state.services = services
    },
  },
  actions: {
  },
  modules: {
  }
})
