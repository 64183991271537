import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue')
  }, {
    path: '/sections/:id',
    name: 'Section',
    component: () => import( /* webpackChunkName: "sections" */ '../views/Section.vue')
  }, {
    path: '/sections',
    name: 'Section',
    component: () => import( /* webpackChunkName: "sections" */ '../views/Sections.vue')
  }, {
    path: '/p/:id',
    name: 'Item',
    component: () => import( /* webpackChunkName: "sections" */ '../views/Item.vue')
  }, {
    path: '/forms/:id',
    name: 'Form',
    component: () => import( /* webpackChunkName: "sections" */ '../views/Form.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // Scroll to top of the page
  window.scrollTo(0, 0)
  next()
})

export default router