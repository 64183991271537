import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/css/theme.min.css'

Vue.config.productionTip = false

import axios from 'axios'
// let api = 'http://localhost:88/eluar/api/'
let api = 'https://api.eluar.net/'
// if in production change api to production
if (process.env.NODE_ENV === 'production') {
    api = 'https://api.eluar.net/'
}
axios.defaults.baseURL = api;

Vue.prototype.$imagePath = api + 'uploads/'
axios.defaults.headers.post['Content-Type'] = 'application/json';

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
